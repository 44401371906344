import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { ClickClass } from "../pages/ClickClass";
import { ClickCard } from "../pages/ClickCard";
import { PDFView } from "../pages/PDFView";
import { About } from "../pages/About";
import { Main } from "../pages/Main";
import { ClickCourse } from "../pages/ClickCourse";
import { TermsofUse } from "../pages/TermsofUse";
import { Users } from "../pages/Users";
import { DrawerContent } from "../components/DrawerContent";
import THEME from '../config/theme';
import { Plans } from "../pages/Plans";
import { EditProfile } from "../pages/EditProfile";
import { SignUp } from "../pages/SignUp";
import { Products } from "../pages/AdminPanel/Products";
import { UsersAnalytics } from "../pages/Users/UserAnalytics";
import Constants from "expo-constants";
import Community from '../pages/Community';
import PostCommunity from '../pages/Community/PostCommunity';
import { CommunityProvider } from '../pages/Community/ContextCommunity/CommunityContext';

const Stack = createNativeStackNavigator();
const Drawer = createDrawerNavigator();
const { guruUserToken } = Constants.manifest.extra;

function DrawerRoutes() {
  return (
    <Drawer.Navigator
      drawerContent={(props) => <DrawerContent {...props} />}
      screenOptions={{
        headerShown: false,
        drawerStatusBarAnimation: "fade",
        drawerActiveBackgroundColor: THEME.COLORS.DRAWER_SELECTED,
        drawerActiveTintColor: THEME.COLORS.BACKGROUND_ABOUT,
        drawerInactiveTintColor: THEME.COLORS.DRAWER_SELECTED,
        drawerStyle: {
          backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
        },
        drawerLabelStyle: {
          fontSize: THEME.FONTSIZE.SMALL,
          fontFamily: THEME.FONTFAMILY.REGULAR,
        },
      }}
    >
      <Drawer.Screen name="Conteudo" component={Main} />
      {(guruUserToken && guruUserToken.length > 0) ? <Drawer.Screen name="Produtos" component={Products} /> : <Drawer.Screen name="Página de Vendas" component={About} />}
      <Drawer.Screen name="Usuários" component={Users} />

      <Drawer.Screen name="Termos de Uso">
        {() => <TermsofUse isAdmin={true} />}
      </Drawer.Screen>
    </Drawer.Navigator>
  );
}
const CommunityScreen = () => (
  <CommunityProvider>
    <Community />
  </CommunityProvider>
);

const CommunityCommentsScreen = () => (
  <CommunityProvider>
    <PostCommunity />
  </CommunityProvider>
);

export function AdminRoutes() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Admin" component={DrawerRoutes} />
      <Drawer.Screen name="Conteudo" component={Main} />
      <Stack.Screen name="Modulo" component={ClickCard} />
      <Stack.Screen name="Aula" component={ClickClass} />
      <Stack.Screen name="PDF" component={PDFView} />
      <Stack.Screen name="Produto" component={ClickCourse} />
      <Stack.Screen name="Página de Vendas" component={About} />
      <Stack.Screen name="Planos" component={Plans} />
      <Stack.Screen name="Cadastro" component={SignUp} />
      <Stack.Screen name="MeuPerfil">
        {props => <EditProfile {...props} userId={props.route.params.userId} editUserAccess={props.route.params.editUserAccess} />}
      </Stack.Screen>
      <Stack.Screen name="TermosdeUso" component={TermsofUse} />
      <Stack.Screen name="UsersAnalytics" component={UsersAnalytics} />
      <Stack.Screen name="Community" component={CommunityScreen} />
      <Stack.Screen
        name="CommunityComments"
        component={CommunityCommentsScreen}
      />
    </Stack.Navigator>
  );
}